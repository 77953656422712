@use 'src/styles/_colors.scss' as colors;

.dateRangeLabel {
  font-family: AvenirBook;
  font-size: 15px;
  letter-spacing: 1.21px;
  line-height: 18px;
  color: colors.$fieldLabel;
  margin-bottom: 5px;
  margin-left: 2px
}

.statTileSection {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  @media (max-width: 1035px) {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

.auctionCountTile {
  width: 230px;
  flex-shrink: 0;
  margin-right: 0.5%;
  @media (max-width: 1035px) {
    width: 40%;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0
  };
  @media (max-width: 600px) {
    width: 80%;
    margin-bottom: 10px;
  }
}

.msgSentTile {
  width: 230px;
  flex-shrink: 0;
  margin-left: 0.5%;
  margin-right: 0.5%;
  @media (max-width: 1035px) {
    width: 40%;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0
  };
  @media (max-width: 600px) {
    width: 80%;
    margin-bottom: 10px;
  }
}

.responsesCountTile {
  width: 230px;
  flex-shrink: 0;
  margin-left: 0.5%;
  margin-right: 0.5%;
  @media (max-width: 1035px) {
    width: 40%;
    margin-left: 0;
    margin-right: 0
  };
  @media (max-width: 600px) {
    width: 80%;
    margin-bottom: 10px;
  }
}

.emptySpaceTile {
  width: calc(96% - 920px);
  margin-left: 0.5%;
  margin-right: 0.5%;
  @media (max-width: 1035px) {
    display: none
  }
}

.totalPriceTile {
  width: 230px;
  flex-shrink: 0;
  display: none;
  margin-left: 0.5%;
  @media (max-width: 1035px) {
    width: 40%;
    margin-left: 0;
    margin-right: 0
  };
  @media (max-width: 600px) {
    width: 80%
  }
}

.clearButton {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-family: AvenirBook;
  font-size: 12px;
  color: colors.$clearButton;
}

.listCount {
  position: absolute;
  top: 25px;
  right: 15px;
  font-family: AvenirBook;
  font-size: 16px
}

.assumedUserInfo {
  margin-bottom: 20px;
  display: flex;
  font-family: AvenirBook;
  font-size: 16px;
  align-items: center
}

.manageLink {
  text-decoration: none;
  font-family: AvenirMedium;
  word-break: keep-all;
  color: colors.$tableManageCellText;
}

.activeLabel {
  word-break: keep;
  color: colors.$activeLabel;
}

.inactiveLabel {
  word-break: keep;
  color: colors.$inactiveLabel;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  padding: 15px;
  position: relative;
  background-color: #fff;
}

.loading {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.container {
  width: 100%;
  height: 100%;
  padding: 15px;
  position: relative;
  background-color: #fff;
}

.assumedUserLabel {
  margin-right: 10px;
}

.assumedUserCloseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1px;
  font-size: 18px
}

.assumedUserCloseButtonLabel {
  margin-left: 3px;
  margin-top: 2px;
  font-size: 12px;
  font-family: Avenir
}

.addEventButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1px
}

.addEventButtonLabel {
  margin-top: 2px;
}

.controlSection {
  width: 100%;
}

.dateRangeField {
  margin-top: 15px;
  font-family: AvenirBook;
  position: relative;
  width: 250px;
}

.tablePlaceholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: AvenirMedium;
  color: colors.$tableHeaderLabel;
  font-size: 16px;
}

.tablePlaceholderAddEventContainer {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.tablePlaceholderAddEventButton {
  color: colors.$main;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  cursor: pointer;
}

.tablePlaceholderAddEventLabel {
  font-size: 16px;
}

.pagination {
  display: flex;
  justify-content: center;
}

