@use 'src/styles/_colors.scss' as colors;

.topContainer {
  display: flex;
  width: 100%;
  align-items: center;
  @media (max-width: 1000px) {
    flexWrap: wrap
  }
}

.topButtonsContainer {
  display: flex;
}

.statTileSection {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  @media (max-width: 1035px) {
    flexWrap: wrap;
    justifyContent: space-evenly
  }
}

.msgSentTile {
  width: 230px;
  flex-shrink: 0;
  margin-left: 0.5%;
  margin-right: 0.5%;
  @media (max-width: 1035px) {
    width: 40%;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0
  }
  @media (max-width: 600px) {
    width: 80%;
    margin-bottom: 10px;
  }
}

.responsesCountTile {
  width: 230px;
  flex-shrink: 0;
  margin-left: 0.5%;
  margin-right: 0.5%;
  @media (max-width: 1035px) {
    width: 40%;
    margin-left: 0;
    margin-right: 0
  }
  @media (max-width: 600px) {
    width: 80%;
    margin-bottom: 10px;
  }
}

.controlRow {
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
}

.dateRangeField {
  width: 250px;
  margin-right: 2%;
  position: relative;
}

.clearButton {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-family: AvenirBook;
  font-size: 12px;
  color: colors.$clearButton;
}

.dateRangeLabel {
  font-family: AvenirBook;
  font-size: 15px;
  letter-spacing: 1.21px;
  line-height: 18px;
  color: colors.$fieldLabel;
  margin-bottom: 5px;
  margin-left: 2px
}

.optionsMenuButton {
  background-color: transparent;
  border: 0;
  color: colors.$main;
  font-family: AvenirMedium;
  font-size: 12px;
  cursor: pointer
}

.tooltip {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 2px;
  font-family: AvenirMedium;
  font-size: 12px;
  margin-right: 15px;
  margin-left: 15px;
}