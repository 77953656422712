@use 'src/styles/_colors.scss' as colors;

.container {
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
}

.titleRow {
  height: 62px
}

.title {
  float: left;
  text-transform: uppercase;
  font-size: 16px;
  font-family: AvenirMedium;
  letter-spacing: 1.49px;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 20px
}

.closeButtonContainer {
  float: right;
  font-size: 25px;
  margin-top: 13px
}

.closeButton {
  cursor: pointer;
}

.rowContainer {
  display: flex;
  margin-bottom: 10px;
}

.fieldLabel {
  font-family: AvenirBook;
  font-size: 15px;
  letter-spacing: 1.21px;
  line-height: 18px;
  color: #6D7278;
  margin-bottom: 5px;
  margin-left: 2px;
}

.buttonContainer {
  float: right;
  margin-bottom: 30px
}

.selectField {
  width: 50%;
  margin-right: 2%;
  position: relative;
}

.messageField {
  width: 100%;
  margin-right: 2%;
  position: relative;
}