@font-face {
  font-family: 'RubikRegular';
  src: local('RubikRegular'), url(./utils/fonts/Rubik-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'RubikMedium';
  src: local('RubikMedium'), url(./utils/fonts/Rubik-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'RubikLight';
  src: local('RubikLight'), url(./utils/fonts/Rubik-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'RubikBold';
  src: local('RubikBold'), url(./utils/fonts/Rubik-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'AvenirBook';
  src: local('AvenirBook'), url(./utils/fonts/Avenir-Book.ttf) format('truetype');
}

@font-face {
  font-family: 'AvenirMedium';
  src: local('AvenirMedium'), url(./utils/fonts/Avenir-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url(./utils/fonts/Avenir.ttc) format('truetype'), url(./utils/fonts/Avenir-Book.ttf) format('truetype');
}

@font-face {
  font-family: 'AvenirHeavy';
  src: local('AvenirHeavy'), url(./utils/fonts/Avenir-Heavy.ttf) format('truetype');
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}