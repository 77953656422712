.container {
  padding: 10px 10px 5px 10px;
  margin: 10px 10px 10px 10px;
}

.labelContainer {
  height: 30px;
}

.label {
  float: left;
  text-transform: uppercase;
  font-size: 16px;
  font-family: AvenirMedium;
  letter-spacing: 1.49px;
  line-height: 22px;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}