@use 'src/styles/_colors.scss' as colors;

.errorLabel {
  color: colors.$errorText;
  text-align: left;
  margin: 0px 14px 0px;
  font-family: AvenirBook;
  font-size: 12px;
}

.sectionLabel {
  text-transform: uppercase;
  font-family: AvenirBook;
  font-size: 13px;
  letter-spacing: 1.21px;
  line-height: 18px;
  color: colors.$lightGrayText;
  margin-bottom: 10px;
  margin-left: 2px
}

.fieldLabel {
  font-family: AvenirBook;
  font-size: 15px;
  letter-spacing: 1.21px;
  line-height: 18px;
  color: colors.$fieldLabel;
  margin-bottom: 5px;
  margin-left: 2px
}

.fieldWithLabel {
  width: 23.5%;
  margin-right: 2%;
  position: relative
}

.fieldWithoutLabel {
  width: 23.5%;
  margin-right: 2%;
  padding-top: 22.6px;
  position: relative
}

.lastFieldWithLabel {
  width: 23.5%;
  position: relative
}

.lastFieldWithoutLabel {
  width: 23.5%;
  padding-top: 22.6px;
  position: relative
}

.rowContainer {
  display: flex;
  margin-bottom: 10px
}

.clearButton {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-family: AvenirBook;
  font-size: 12px;
  color: colors.$clearButton;
}

.fieldSubLabel {
  font-family: AvenirHeavy;
  font-size: 15px;
  letter-spacing: 1.21px;
  line-height: 18px;
  color: colors.$fieldLabel;
  margin-bottom: 5px;
  margin-left: 7px
}

.formSectionWarningLabel {
  color: #faa307;
  text-align: left;
  margin: 0px 2px 10px;
  font-family: AvenirBook;
  font-size: 15px
}

.container {
  margin-left: 20px;
  margin-right: 20px;
}

.topBarContainer {
  height: 62px;
}

.title {
  float: left;
  text-transform: uppercase;
  font-size: 16px;
  font-family: AvenirMedium;
  letter-spacing: 1.49px;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 20px
}

.closeButtonContainer {
  float: right;
  font-size: 25px;
  height: 62px;
  margin-top: 13px;
}

.closeButton {
  cursor: pointer;
}

.form {
  min-width: 360px;
}

.formSection {
  border-bottom: 1px solid colors.$formSectionDivider;
  margin-top: 10px;
  :first-child {
    margin-top: 0;
  }
}

.signatureField {
  margin-right: 2%;
  position: relative;
  width: 47%;
}

.messageSection {
  margin-top: 10px;
  margin-bottom: 10px;
}

.availableTemplatesLabelMargin {
  margin-top: 15px;
}

.availableTemplatesPlaceholder {
  height: 30px;
  margin-top: 10px;
  margin-left: 5px;
  font-family: AvenirBook;
  font-size: 15px;
  color: colors.$placeholderText;
}

.messageTemplateContainer {
  margin-bottom: 2px;
}

.templateCheckboxIcon {
  font-size: 15px;
  border-radius: 3px;
  height: 15px;
  color: colors.$checkboxIcon;
  background-color: colors.$checkboxChecked;
  margin: 0
}

.messageCountLabel {
  color: colors.$messageCountLabel;
}

.errorLabelFloat {
  float: left;
}

.customTemplatesLabelMargin {
  margin-top: 20px;
}

.customTemplateContainer {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.customTemplateText {
  width: 90%;
  font-family: AvenirBook;
  color: colors.$lightGrayText;
  font-size: 15px;
  padding-left: 5px;
  white-space: break-spaces;
  margin-top: 3px;
  word-break: break-all;
}

.editCustomTemplate {
  width: 5%;
  text-align: center;
  color: blue;
  font-size: 22px;
  cursor: pointer;
}

.removeCustomTemplate {
  width: 5%;
  text-align: center;
  cursor: pointer;
}

.customTemplateForm {
  width: 100%;
  margin-top: 20px;
}

.customTemplateFormFields {
  display: flex;
  justify-content: center;
}

.customTemplateFormMessageCount {
  margin-right: 10%;
  font-family: AvenirBook;
  font-size: 14px;
  margin-top: -15px;
  margin-bottom: 37px
}

.customTemplateFormButtonContainer {
  float: right;
  margin-top: -45px;
}

.customTemplateFormButtonContent {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.customTemplateFormButtonIcon {
  margin-top: 3px;
  margin-right: 3px;
}

.customTempalteTextField {
  width: 100%;
  position: relative;
}

.emailNotificationSection {
  margin-top: 10px;
}

.emailNotificationListLabel {
  margin-right: 2%;
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.emailNotificationListPlaceholder {
  height: 30px;
  margin-top: 10px;
  margin-left: 5px;
  font-family: AvenirBook;
  font-size: 15px;
  color: colors.$placeholderText;
}

.emailNotificationList {
  display: flex;
  flex-wrap: wrap;
}

.emailNotificationListTile {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-family: AvenirBook;
  height: 30px;
  background-color: colors.$emailListTile;
  color: #8798AD;
  margin: {
    right: 5px;
    bottom: 3px;
  };
  padding: {
    top: 5px;
    bottom: 5px;
    left: 10px;
    right: 5px;
  };
  border-radius: 5px;
}

.emailNotificationListTileButtonContainer {
  margin-top: 7px;
  font-size: 20px
}

.emailNotificationListTileButton {
  cursor: pointer;
}

.emailNotificationField {
  width: 100%;
  margin-top: 15px;
  margin-right: 2%;
  position: relative
}

.emailNotificationForm {
  width: 100%;
}

.emailNotificationFormContent {
  display: flex;
  width: 100%;
}

.emailNotificationFormField {
  width: 47%;
}

.emailNotificationFormButton {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.emailNotificationFormButtonIcon {
  margin-top: 3px;
  margin-right: 3px;
}

.submitButtonContainer {
  float: right;
  margin-bottom: 30px;
}

.flexSpacer {
  flex-grow: 1;
}

.loadingContainer {
  width: 100%;
  height: 100%;
  padding: 15px;
  position: relative;
  background-color: #fff;
}

.loading {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.switchLabel {
  margin-left: 10px;
  font-family: Avenir;;
  font-size: 15px;
  color: colors.$fieldLabel
}

.warningLabel {
  margin-left: 5px;
  color: colors.$warningLabel
}

.switchContainer {
  display: flex;
  width: 100%;
  margin-right: 2%;
}

.specialModeRow {
  align-items: center;
  background-color: colors.$specialMode;
  padding: 12px 15px 6px 15px;
}

.specialModeSwitchLabel {
  margin-left: 10px;
  font-family: Avenir;;
  font-size: 15px;
  color: #fff;
}

.specialModeIcon {
  margin-right: 20px;
}

.onlySwitchRow {
  margin: 20px 0px;
}