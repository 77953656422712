$main: #4566C0;
$fieldLabel: #6D7278;
$lightGrayText: #8798AD;
$clearButton: #BFC5D2;
$tableManageCellText: #4566C0;
$tableHeaderLabel: #BFC5D2;
$errorText: red;
$formSectionDivider: #BFC5D2;
$placeholderText: rgba(109,114,120,0.5);
$checkboxIcon: #FFFFFF;
$checkboxChecked: #4566C0;
$messageCountLabel: #ffa500;
$warningLabel: #ffa500;
$emailListTile: rgba(216,216,216,0.5);
$activeLabel: #30A53B;
$inactiveLabel: #E02020;
$specialMode: #2E384D;